import {
  Dispatch,
  FC,
  LegacyRef,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Document, Page } from 'react-pdf';

import { FileType } from '../../enums/file-type';

import styles from './DocumentView.module.scss';
import { REACT_PDF_CLASS_SELECTOR } from '../../constants';
import { DocumentViewTarget } from '../../enums';

interface DocumentViewProps {
  file: FileType;
  onLoadError?: (e: Error) => void;
  setPage?: Dispatch<SetStateAction<number>>;
  signaturePage?: number;
  signatureHtml?: HTMLElement | null;
  step?: number;
  target?: DocumentViewTarget;
}

const REACT_PDF_PAGE_CLASS_SELECTOR = '.react-pdf__Page';
const PAGE_HEIGHT = 860;
const DOCUMENT_WIDTH = 430;
const DOCUMENT_HEIGHT = 360;

export const DocumentView: FC<DocumentViewProps> = memo(
  ({
    file,
    onLoadError,
    setPage,
    signaturePage,
    signatureHtml,
    step,
    target,
  }) => {
    const [sumOfPages, setSumOfPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const documentRef = useRef<LegacyRef<HTMLDivElement> | undefined>();
    const reactPdfContainer: Element = document.querySelector(
      `.${REACT_PDF_CLASS_SELECTOR}`,
    ) as Element;

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
      setSumOfPages(numPages);
    };

    const getVisibleHeight = (element: HTMLElement) => {
      const { scrollTop, clientHeight } = reactPdfContainer;
      const scrollBot = scrollTop + clientHeight;
      const containerRect: DOMRect =
        reactPdfContainer?.getBoundingClientRect() as DOMRect;
      const eleRect = element.getBoundingClientRect();
      const eleTop = eleRect.top - containerRect?.top + scrollTop;
      const eleBot = eleTop + element.offsetHeight;
      const visibleTop = eleTop < scrollTop ? scrollTop : eleTop;
      const visibleBot = eleBot > scrollBot ? scrollBot : eleBot;
      const currentPage =
        scrollTop === 0 ? 1 : Math.ceil(scrollTop / PAGE_HEIGHT);
      setPage?.(currentPage);

      return visibleBot - visibleTop;
    };

    const changePage = useCallback(
      (offset: number) => () =>
        setPageNumber((prevPageNumber) => {
          setPage?.(prevPageNumber + offset);
          return prevPageNumber + offset;
        }),
      [],
    );

    const navigateToPage = useCallback(
      (page: number) => () => {
        setPage?.(page);
        setPageNumber(page);
      },
      [],
    );

    useEffect(() => {
      const pages = document.querySelectorAll(REACT_PDF_PAGE_CLASS_SELECTOR);
      reactPdfContainer?.addEventListener(
        'scroll',
        () => {
          for (let i = 0; i < pages.length; i++) {
            if (
              getVisibleHeight(pages[i] as HTMLElement) >=
              reactPdfContainer.clientHeight / 2
            ) {
              setPageNumber(i + 1);
            }
          }
        },
        false,
      );

      return () => {
        reactPdfContainer?.removeEventListener('scroll', () => ({}));
      };
    }, [reactPdfContainer]);

    const elementSignature = useMemo(
      () =>
        document.querySelector('.uploaded-document .react-draggable-dragged'),
      [step, signatureHtml],
    );

    const documentPdf = useMemo(
      () => document.querySelector('.uploaded-document'),
      [step, signatureHtml],
    );

    const documentSize = useMemo(
      () =>
        target === DocumentViewTarget.HistoryModal
          ? { width: DOCUMENT_WIDTH, height: DOCUMENT_HEIGHT }
          : {},
      [target],
    );

    useEffect(() => {
      if (step === 1) {
        documentPdf &&
        !elementSignature &&
        signatureHtml &&
        pageNumber === signaturePage
          ? documentPdf?.appendChild(signatureHtml)
          : elementSignature && elementSignature.remove();
      }
      step === 3 && elementSignature && elementSignature.remove();
    }, [
      signaturePage,
      step,
      pageNumber,
      signatureHtml,
      elementSignature,
      documentPdf,
    ]);

    return (
      <>
        <Document
          inputRef={documentRef as LegacyRef<HTMLDivElement>}
          className={file ? `${styles.uploadedDocument} uploaded-document` : ''}
          renderMode="canvas"
          options={{ workerSrc: '/pdf.worker.js' }}
          file={file}
          loading="Loading"
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
        >
          <Page {...documentSize} pageNumber={pageNumber} />
        </Document>
        <div className="d-flex justify-content-center mt-3">
          <div className="px-1">
            <button
              disabled={pageNumber <= 1}
              className={`btn btn-link text-decoration-none text-primary ${styles.btnPage}`}
              onClick={navigateToPage(1)}
            >
              <i className="icon-arrow-first"></i>
            </button>
          </div>
          <div className="px-1">
            <button
              disabled={pageNumber <= 1}
              className={`btn btn-link text-decoration-none text-primary ${styles.btnPage}`}
              onClick={changePage(-1)}
            >
              <i className="icon-arrow-left"></i>
            </button>
          </div>
          <div className="px-1 d-flex align-items-center fs-6">
            {pageNumber || (sumOfPages ? 1 : '--')} of {sumOfPages || '--'}
          </div>
          <div className="px-1">
            <button
              disabled={pageNumber >= sumOfPages}
              className={`btn btn-link text-decoration-none text-primary ${styles.btnPage}`}
              onClick={changePage(1)}
            >
              <i className="icon-arrow-right"></i>
            </button>
          </div>
          <div className="px-1">
            <button
              disabled={pageNumber >= sumOfPages}
              className={`btn btn-link text-decoration-none text-primary ${styles.btnPage}`}
              onClick={navigateToPage(sumOfPages)}
            >
              <i className="icon-arrow-last"></i>
            </button>
          </div>
        </div>
      </>
    );
  },
);
