export enum PrivacyPolicyId {
  DEFINITIONS = 'definitions',
  INTRODUCTION = 'Introduction',
  OWNER_AND_DATA_CONTROLLER = 'ownerAndDataController',
  DATA_COLLECTION_AND_USE = 'dataCollectionAndUse',
  DATA_PROCESSING_ROLES = 'dataProcessingRoles',
  USER_RIGHTS = 'userRights',
  ADDITIONAL_INFORMATION_DATA_COLLECTION = 'additionalInformationDataCollection',
  SECURITY_MEASURES = 'securityMeasures',
  DOCUMENTS_MODULE = 'documentsModule',
  PAYMENT_MODULE = 'PaymentModule',
  NOTIFICATION_FEATURE = 'notificationFeature',
  CHANGE_PRIVACY_POLICY = 'Changes to This Privacy Policy',
  CONTACT_US = 'contactUs',
}
