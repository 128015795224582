import { QRCode } from 'react-qrcode-logo';
import { FC, memo } from 'react';

import { Theme } from '../../enums';

import ZamLoginContainer from '../../assets/images/zam-login-container.png';
import ZamDecorLogin from '../../assets/images/zam-decor-login.svg';
import DotgovLoginContainer from '../../assets/images/dotgov-login-container.png';
import DotgovDecorLogin from '../../assets/images/dotgov-decor-login.svg';
import googlePlay from '../../assets/images/google.svg';
import applePlay from '../../assets/images/apple.svg';
import mobileLogo from '../../assets/images/mobile_logo.svg';
import { globalConfig } from '../../configuration/config';

import styles from './LoginForm.module.scss';

interface LoginFormProps {
  handleSubmit: () => void;
  buttonLabel: string;
  verified?: boolean;
}

export const LoginForm: FC<LoginFormProps> = memo(
  ({ handleSubmit, buttonLabel, verified }) => {
    return (
      <div
        style={{
          backgroundImage: `url(${
            globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
              ? ZamLoginContainer
              : DotgovLoginContainer
          })`,
        }}
        className={`${
          verified ? styles.loginEnter : styles.loginUnverified
        } login-container`}
      >
        <div
          className={`${styles.loginBackground} login-background rounded h-100 d-flex flex-column align-items-center`}
        >
          <div className="w-100 mt-4">
            <h3 className="text-white pt-4 px-4">
              {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                ? 'ZamSign'
                : 'DotGov Sign'}
            </h3>
            <div>
              <img
                src={
                  globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                    ? ZamDecorLogin
                    : DotgovDecorLogin
                }
                alt="Decor"
              />
            </div>
            {verified && (
              <div className="px-4 pt-3">
                <p className="text-white mb-0">Government Digital</p>
                <p className="text-white mb-0">Signature Service</p>
              </div>
            )}
          </div>
          <div className="w-100 h-100 d-flex justify-content-center flex-column px-3">
            {verified ? (
              <button
                className="btn btn-lg btn-primary text-white"
                onClick={handleSubmit}
              >
                <p className="m-0">{buttonLabel}</p>
              </button>
            ) : (
              <div className="text-center">
                <p className="text-error unverified mt-5 mb-3">
                  Unverified Identity
                </p>
                <p className="text-white unverified-info">
                  Your account was created with an unverified identity,
                  resulting in limited functionality and access. To unlock full
                  access, please register to verify your identity.
                </p>
                <p className="text-white unverified-info mt-5">
                  Please continue with ZamGov
                </p>
                <div className="d-flex justify-content-between gap-1 w-100 mt-3 flex-sm-row flex-column">
                  <div>
                    <div className="mb-2">
                      <a href={globalConfig.config.REACT_APP_IOS_STORE_URL}>
                        <img
                          className="mx-auto"
                          src={applePlay}
                          alt="Apple Store"
                        />
                      </a>
                    </div>
                    <div>
                      <a href={globalConfig.config.REACT_APP_GOOGLE_STORE_URL}>
                        <img
                          className="mx-auto"
                          src={googlePlay}
                          alt="Google Play"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center flex-grow-1 text-white">
                    or
                  </div>
                  <div>
                    <div
                      style={{
                        height: 'auto',
                        margin: '0 auto',
                        width: '100%',
                      }}
                    >
                      <QRCode
                        size={95}
                        quietZone={5}
                        value={globalConfig.config.REACT_APP_DYNAMIC_STORE_URL}
                        logoImage={mobileLogo}
                        removeQrCodeBehindLogo={true}
                        ecLevel="H"
                        qrStyle="dots"
                        logoWidth={30}
                        id="dynamic-store-qr-code"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center justify-content-sm-end align-items-center">
                  <p className={`${styles.qrText} download-app text-white`}>
                    Scan to download the app
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);
