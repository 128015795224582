import React, { useEffect } from 'react';
import { MenuItems } from '../../interfaces';
import { MenuContent } from '../../components/MenuContent/MenuContent';
import styles from './delete-account.module.scss';

const menuItems: MenuItems[] = [
  {
    label: 'Access the Account Settings',
    id: 'Step1',
  },
  {
    label: 'Save Your Personal Activity Data (Optional)',
    id: 'Step2',
  },
  {
    label: 'Initiate the Account Deletion Process',
    id: 'Step3',
  },
  {
    label: 'Confirm the Account Deletion',
    id: 'Step4',
  },
];

export const DeleteAccountPage = () => {
  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

  return (
    <section className={`${styles.account} container mt-5 p-5`}>
      <div className="row">
        <div className="col-9">
          <header>
            <h3>How to delete Account</h3>
          </header>
          <p className="small">Last update: 12/02/2025</p>
          <h5 className="mt-4" id="Step1">
            Step 1: Access the Account Settings
          </h5>
          <p>
            To begin the process of deleting your account, you need to access
            your account settings. Follow these steps:
          </p>
          <ol>
            <li>
              <strong>Log in to Your Account:</strong>
              <ul>
                <li>
                  Open your web browser and go to the login page of our website
                  or app.
                </li>
                <li>Enter your username and password to log in.</li>
              </ul>
            </li>
            <li>
              <strong>Navigate to Account Settings:</strong>
              <ul>
                <li>
                  Once logged in, locate the user profile icon or your account
                  name in the upper-right corner of the page.
                </li>
                <li>Click on it to open a dropdown menu.</li>
                <li>Select “My profile” from the dropdown options.</li>
              </ul>
            </li>
          </ol>
          <h5 className="mt-4" id="Step2">
            Step 2: Save Your Personal Activity Data (Optional)
          </h5>
          <p>
            Before deleting your account, you may want to save your personal
            activity data. Follow these steps to download your data:
          </p>
          <ol>
            <li>
              <strong>Locate the Data Download Option:</strong>
              <ul>
                <li>
                  In the Account Settings page, look for a section labeled
                  “Recent activity”.
                </li>
                <li>
                  Find the option for “Download All” for Sign-ins and Consents.
                </li>
              </ul>
            </li>
            <li>
              <strong>Request Data Export:</strong>
              <ul>
                <li>Click on the “Download All” button.</li>
                <li>
                  Our system will process your request and compile your data.
                  This may take a few seconds to several minutes, depending on
                  the volume of data.
                </li>
              </ul>
            </li>
            <li>
              <strong>Download Your Data:</strong>
              <ul>
                <li>
                  Once your data is ready, the browser will automatically
                  download your data.
                </li>
                <li>
                  The downloaded data is in CSV format that can be opened with
                  any text editor or with Microsoft Excel application.
                </li>
              </ul>
            </li>
          </ol>
          <h5 className="mt-4" id="Step3">
            Step 3: Initiate the Account Deletion Process
          </h5>
          <p>
            Once you are in the account settings, follow these steps to start
            the account deletion process:
          </p>
          <ul>
            <li>
              In the Account Settings page, look for a section labeled “Delete
              account”.
            </li>
          </ul>
          <h5 className="mt-4" id="Step4">
            Step 4: Confirm the Account Deletion
          </h5>
          <p>
            In the “Delete account” section, you will need to confirm your
            decision:
          </p>
          <ol>
            <li>
              <strong>Read the Account Deletion Information:</strong>
              <ul>
                <li>
                  Carefully read the information provided about what will happen
                  once your account is deleted. Make sure you understand that
                  this action is permanent and cannot be undone.
                </li>
              </ul>
            </li>
            <li>
              <strong>Confirm Deletion:</strong>
              <ul>
                <li>
                  You may be asked to confirm your decision one final time in a
                  popup window. Click “Delete Account” to proceed.
                </li>
              </ul>
            </li>
          </ol>
        </div>
        <div className={`${styles.menu} col-3 position-fixed`}>
          <MenuContent menuItems={menuItems} />
        </div>
      </div>
    </section>
  );
};
