export enum DocumentType {
  SIGN = 'sign',
  VERIFY = 'verify',
}

export enum Pages {
  DocumentsArchive = 'DocumentsArchive',
  History = 'History',
}

export enum DocumentViewTarget {
  SignPage = 'signPage',
  HistoryModal = 'historyModal',
}
