import { useState } from 'react';
import { Link } from 'react-router-dom';

import { FeedbackDialog } from '../index';
import DotgovFooterLogo from '../../assets/images/dotgov-footer-logo.webp';
import ZamFooterLogo from '../../assets/images/zam-footer-logo.svg';
import styles from './Footer.module.scss';
import { routes } from '../../models';
import { DOTGOV_FOOTER_TEXT, ZAM_FOOTER_TEXT } from '../../constants';
import { Theme } from '../../enums';
import { globalConfig } from '../../configuration/config';

export const Footer = () => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  return (
    <footer className="bottom-0 w-100 pt-2 position-relative bg-black">
      <div className={`${styles.footer} container pt-2 pb-1`}>
        <div className="row bg-black my-5 d-flex justify-content-evenly">
          <div className="col-md-4 col-lg-3 d-flex flex-column align-items-center">
            <div className="logo">
              <img
                src={
                  globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                    ? ZamFooterLogo
                    : DotgovFooterLogo
                }
                alt="DotGov Sign"
                className="img-fluid mb-3"
              />
            </div>
            <div className="w-75">
              <p className="p5-400 text-white text-center">
                {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                  ? 'ZamSign - E-Government Digital Signature Service'
                  : 'DotGovSign - E-Government Digital Signature Service'}
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <button
                className="btn btn-outline-primary custom-fs-2 py-2 px-3"
                onClick={() => setShowFeedbackDialog(!showFeedbackDialog)}
              >
                Leave feedback
              </button>
              {showFeedbackDialog && (
                <FeedbackDialog
                  title="Support feedback"
                  onClose={() => setShowFeedbackDialog(false)}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <p className="mb-2 p1-300 text-grey-4">Useful Links</p>
            <hr className="text-grey-4 mt-2" />
            <ul className="p-0">
              <Link
                to={routes.deleteAccount}
                className="nav-link text-warning p-0 my-3 p2-600"
              >
                How to delete my ZamPass account
              </Link>
              <Link
                to={routes.termsAndConditions}
                className="nav-link text-warning p-0 my-3 p2-600"
              >
                Terms and Conditions
              </Link>
              <Link
                to={routes.privacyPolicy}
                className="nav-link text-warning my-3 p-0 p2-600"
              >
                ZamGov Privacy Policy
              </Link>
              <Link
                to={routes.faq}
                className="nav-link text-warning p-0 p2-600"
              >
                FAQ
              </Link>
            </ul>
          </div>
          <div className="col-md-4 col-lg-3 px-4">
            <p className="mb-2 p1-300 text-grey-4">Support</p>
            <hr className="text-grey-4 mt-2" />
            <div>
              <p className="mb-1 p3-400 text-white">Phone:</p>
              <div>
                <a className="text-main custom-fs-1 text-white text-decoration-none">
                  +260{' '}
                </a>
                <a
                  href="tel:+260211 428 600"
                  className="custom-fs-2 text-decoration-none text-warning"
                >
                  211 428 600
                </a>
              </div>
              <small className="text-grey-4 p5-400">(short code: 2008)</small>
            </div>
            <div className="mt-2">
              <p className="mb-1 p3-400 text-white">Email:</p>
              <a
                href="mailto: infotest@gmail.com"
                className="text-warning p2-400 text-decoration-none"
              >
                infotest@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-success py-1">
        <div
          className={`${styles.copyright} copyright container fst-normal d-flex justify-content-center`}
        >
          <span className="text-white custom-fs-5">
            {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
              ? ZAM_FOOTER_TEXT
              : DOTGOV_FOOTER_TEXT}
          </span>
        </div>
      </div>
    </footer>
  );
};
