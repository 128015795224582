import { FC, memo } from 'react';

import { Button, ProfileHistory } from '../../interfaces';
import { Tooltip } from '../';
import { TooltipDirection } from '../../enums/history-key';

const MODAL_ID = '#historyDetails';

interface ActionButtonsProps {
  buttons: Button<ProfileHistory>[];
  item: ProfileHistory;
}

export const ActionButtons: FC<ActionButtonsProps> = memo(
  ({ buttons, item }) => (
    <>
      {buttons.map(
        ({ name, icon, tooltip, onClick, hidden }, index: number) => (
          <div
            key={`button-wrapper-${index}`}
            className={hidden ? 'd-none' : ''}
          >
            <Tooltip
              content={tooltip}
              direction={TooltipDirection.BOTTOM}
              delay={0}
            >
              <button
                className="d-flex btn btn btn-link action-button"
                data-bs-toggle={`${name === 'view' ? 'modal' : ''}`}
                data-bs-target={MODAL_ID}
                onClick={() => onClick(item)}
              >
                <i className={icon} />
              </button>
            </Tooltip>
          </div>
        ),
      )}
    </>
  ),
);
