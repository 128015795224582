import {
  BaseSyntheticEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  memo,
} from 'react';
import { pdfjs } from 'react-pdf';

import { Stepper } from '../Stepper/Stepper';
import { Buttons } from '../Buttons/Buttons';
import { DocumentType, DocumentViewTarget, StatusStep } from '../../enums';
import { DocumentUploadInput } from '../DocumentUploadInput/DocumentUploadInput';
import { DocumentView } from '../DocumentView/DocumentView';
import { ButtonSetting } from '../../interfaces';
import { FileType } from '../../enums/file-type';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface WizardProps {
  title: string;
  step: number;
  section: DocumentType;
  subtitle: string;
  file: FileType;
  buttons: ButtonSetting[];
  viewDocStep: StatusStep;
  onChange: (e: BaseSyntheticEvent) => void;
  component: () => ReactNode;
  onLoadError: () => void;
  setPage?: Dispatch<SetStateAction<number>>;
  signaturePage?: number;
  signatureHtml?: HTMLElement | null;
}

export const Wizard: FC<WizardProps> = memo(
  ({
    title,
    step,
    section,
    subtitle,
    component,
    buttons,
    viewDocStep,
    onChange,
    file,
    onLoadError,
    setPage,
    signaturePage,
    signatureHtml,
  }) => {
    return (
      <div className="container pt-5">
        <div className="row pt-5">
          <div className="col-12">
            <h3 className="open-sans-bold">{title} </h3>
          </div>
          <div className="col-12 mt-3 mb-4">
            <Stepper step={step} section={section} />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <h5 className="mb-4">{subtitle}</h5>
              </div>
              <div className="col-12 mb-5">
                <div className="row">
                  <div
                    className={`col-md-6 col-sm-12 position-relative ${
                      viewDocStep !== StatusStep.FIRST ? 'h-100' : ''
                    }`}
                  >
                    {viewDocStep === StatusStep.FIRST ? (
                      section === DocumentType.VERIFY && file ? (
                        <DocumentView
                          file={file}
                          onLoadError={onLoadError}
                          setPage={setPage}
                          signaturePage={signaturePage}
                          signatureHtml={signatureHtml}
                          step={step}
                          target={DocumentViewTarget.SignPage}
                        />
                      ) : (
                        <DocumentUploadInput onChange={onChange} />
                      )
                    ) : (
                      <DocumentView
                        file={file}
                        onLoadError={onLoadError}
                        setPage={setPage}
                        signaturePage={signaturePage}
                        signatureHtml={signatureHtml}
                        step={step}
                        target={DocumentViewTarget.SignPage}
                      />
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 mt-md-0 mt-sm-4">
                    {component()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 mb-5">
            <div className="row">
              <Buttons buttons={buttons} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
