export enum FaqId {
  ZamSign = 'ZamSign',
  HOW_DOES_ZAMSIGN_WORK = 'howZamSignWork',
  WHO_CAN_USE_ZAMSIGN = 'whoCanUseZamSign',
  DOCUMENT_SIGNED_WITH_ZAMSIGN = 'documentSignedWithZamSign',
  VERIFIED_ACCOUNT_WITH_ZAMSIGN = 'verifiedWithZamSign',
  ACCESS_WITH_ZAMSIGN = 'acceptedWithZamSign',
  ASSOCIATES_WITH_ZAMSIGN = 'asociatedWithZamSign',
  ENCOUNTER_ISSUES_WITH_ZAMSIGN = 'encounterWithZamSign',
  MOBILE_DEVICE_ZAMSIGN_WORK = 'mobilityDeviationWithZamSign',
  SECURITY_ZAMSIGN = 'secureZamSign',
}
