import React, { useEffect } from 'react';
import { MenuItems } from '../../interfaces';
import { MenuContent } from '../../components/MenuContent/MenuContent';
import styles from './Faq.module.scss';
import { FaqId } from '../../enums/faq-id';

const menuItems: MenuItems[] = [
  {
    label: 'What is ZamSign?',
    id: FaqId.ZamSign,
  },
  {
    label: 'How does ZamSign work?',
    id: FaqId.HOW_DOES_ZAMSIGN_WORK,
  },
  {
    label: 'Who can use ZamSign?',
    id: FaqId.WHO_CAN_USE_ZAMSIGN,
  },
  {
    label: 'What types of documents can be signed with ZamSign?',
    id: FaqId.DOCUMENT_SIGNED_WITH_ZAMSIGN,
  },
  {
    label: 'Do I need a verified account to use ZamSign?',
    id: FaqId.VERIFIED_ACCOUNT_WITH_ZAMSIGN,
  },
  {
    label: 'How do I access ZamSign?',
    id: FaqId.ACCESS_WITH_ZAMSIGN,
  },
  {
    label: 'Are there any fees associated with using ZamSign?',
    id: FaqId.ASSOCIATES_WITH_ZAMSIGN,
  },
  {
    label: 'What should I do if I encounter issues with ZamSign?',
    id: FaqId.ENCOUNTER_ISSUES_WITH_ZAMSIGN,
  },
  {
    label: 'Can I use ZamSign on mobile devices?',
    id: FaqId.MOBILE_DEVICE_ZAMSIGN_WORK,
  },
  {
    label: 'How secure is ZamSign?',
    id: FaqId.SECURITY_ZAMSIGN,
  },
];

export const FAQPage = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <section className={`${styles.fqaStyle} container mt-5 p-5`}>
      <div className="row">
        <div className="col-9">
          <header>
            <h3>Frequently Asked Questions (FAQ) for ZamSign</h3>
          </header>
          <h5 className="mt-4" id={FaqId.ZamSign}>
            1. What is ZamSign?
          </h5>
          <p>
            ZamSign is a government-provided electronic signature service that
            enables users to sign and verify electronic documents securely.
          </p>
          <h5 className="mt-4" id={FaqId.HOW_DOES_ZAMSIGN_WORK}>
            2. How does ZamSign work?
          </h5>
          <p>
            ZamSign integrates with various government platforms, allowing users
            to apply electronic signatures to documents and verify the
            authenticity of signed documents.
          </p>
          <h5 className="mt-4" id={FaqId.WHO_CAN_USE_ZAMSIGN}>
            3. Who can use ZamSign?
          </h5>
          <p>
            ZamSign is available to all Zambian citizens who need to sign or
            verify electronic documents in their interactions with government
            services.
          </p>
          <h5 className="mt-4" id={FaqId.DOCUMENT_SIGNED_WITH_ZAMSIGN}>
            4. What types of documents can be signed with ZamSign?
          </h5>
          <p>ZamSign supports PDF document type.</p>
          <h5 className="mt-4" id={FaqId.VERIFIED_ACCOUNT_WITH_ZAMSIGN}>
            5. Do I need a verified account to use ZamSign?
          </h5>
          <p>
            Yes, in order to access the digital signature service, you must have
            a verified ZamPass account. This ensures secure authentication and
            prevents unauthorized access to the electronic signature
            functionality. If you do not have a verified ZamPass account, you
            can complete the verification process through the ZamGov app.
          </p>
          <h5 className="mt-4" id={FaqId.ACCESS_WITH_ZAMSIGN}>
            6. How do I access ZamSign?
          </h5>
          <p>
            You can access ZamSign through the official government portal or via
            integrated government services that offer electronic signature
            capabilities.
          </p>
          <h5 className="mt-4" id={FaqId.ASSOCIATES_WITH_ZAMSIGN}>
            7. Are there any fees associated with using ZamSign?
          </h5>
          <p>
            ZamSign is provided as a free service to facilitate secure
            electronic transactions between citizens, businesses, and government
            entities.
          </p>
          <h5 className="mt-4" id={FaqId.ENCOUNTER_ISSUES_WITH_ZAMSIGN}>
            8. What should I do if I encounter issues with ZamSign?
          </h5>
          <p>
            If you experience any problems using ZamSign, please contact our
            support team at info@eservices.gov.zm or call our helpline at
            +260-XXX-XXXXXX.
          </p>
          <h5 className="mt-4" id={FaqId.MOBILE_DEVICE_ZAMSIGN_WORK}>
            9. Can I use ZamSign on mobile devices?
          </h5>
          <p>
            Yes, ZamSign is accessible on both desktop and mobile devices,
            ensuring flexibility and convenience for users.
          </p>
          <h5 className="mt-4" id={FaqId.SECURITY_ZAMSIGN}>
            10. How secure is ZamSign?
          </h5>
          <p>
            ZamSign employs advanced security measures, including encryption and
            multi-factor authentication, to ensure the integrity and
            confidentiality of your electronic documents and signatures. This
            FAQ aims to provide clear and concise information about
            ZamSign&apos;s functionalities and usage, ensuring a smooth and
            efficient experience for all users.
          </p>
        </div>
        <div className={`${styles.menu} col-3 position-fixed`}>
          <MenuContent menuItems={menuItems} />
        </div>
      </div>
    </section>
  );
};
