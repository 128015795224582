import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProps } from 'formik';

import { FormField, Props } from '../../interfaces';
import { DynamicForm, Tooltip } from '../';
import { getSearchParams, updateSearchParams } from '../../utils/utils';
import { FilterTableSelection, Selection } from './FilterTableSelection';
import { TooltipDirection } from '../../enums/history-key';

import styles from './FilterTable.module.scss';

interface FilterTableProps {
  items: FormField[];
  onChange?: (url: string) => void;
  selection?: Selection;
  toDto?: (filters: Props) => Props;
  onSubmit: (values: Props) => void;
  onReset?: () => void;
  subscribeKey?: string;
}

export const FilterTable: FC<FilterTableProps> = memo(
  ({
    items,
    selection,
    onSubmit: onSubmitHandler,
    onReset,
    subscribeKey = 'name',
  }) => {
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const formRef = useRef<FormikProps<object>>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const defaults = useMemo(() => {
      return items.reduce((acc, currentValue) => {
        return { ...acc, [currentValue.name]: '' };
      }, {});
    }, [items]);

    const initialValues = useMemo(
      () => ({ ...defaults, ...getSearchParams(searchParams) }),
      [],
    );

    const onChangeUrl = useCallback(
      (defaultsFilter: Props, filterKey = '', reset = false) => {
        const args = formRef?.current?.values as Props;
        const filter = {
          created: args?.created ?? '',
          name: args?.name ?? '',
          signed: args.signed ?? '',
          statuses: args.statuses ?? '',
        };
        if (filterKey) {
          filter[filterKey as keyof typeof filter] = '';
        }
        const newParams = updateSearchParams(
          searchParams,
          reset ? defaultsFilter : filter,
        );
        navigate('?' + newParams);
      },
      [formRef, searchParams],
    );

    const onSubmit = (values: object) => {
      onSubmitHandler(values);
    };

    const applyFilters = useCallback(() => {
      onChangeUrl(defaults, '');
      formRef?.current?.submitForm();
    }, [onChangeUrl, defaults]);

    const resetHandler = useCallback(() => {
      onChangeUrl(defaults, '', true);
      onReset?.();
      formRef?.current?.setValues(defaults);
    }, [defaults]);

    const setShowHandler = useCallback(() => {
      setShowFilter((show) => !show);
    }, []);

    const onRemoveFilter = useCallback(
      (filterKey: string) => () => {
        const input = items?.find((item) => item.name === filterKey);
        input?.props?.onChange?.({
          value: '',
          form: formRef.current as FormikProps<Props>,
          inputs: items,
        });
        formRef?.current?.setFieldValue(filterKey, '');
        onChangeUrl(defaults, filterKey);
        formRef?.current?.submitForm();
      },
      [onChangeUrl, defaults, formRef?.current?.values],
    );

    const onRefreshHandler = useCallback(() => {
      formRef?.current?.submitForm();
    }, []);

    useEffect(() => {
      const params = getSearchParams(searchParams) as Props;
      formRef?.current?.setValues(params);
    }, [searchParams]);

    return (
      <div className="filter-table">
        <div className="d-flex justify-content-between">
          <div className="d-flex ">
            {items.map(
              (item, key) =>
                searchParams?.get(item.name) && (
                  <div
                    className="d-flex filter-tag align-items-center open-sans-semibold m-1 p-1"
                    key={`tag-${key}`}
                  >
                    {item.label}:{searchParams?.get(item.name)}
                    <i
                      style={{ fontSize: '24px' }}
                      className="icon-close remove-icon cursor-pointer"
                      onClick={onRemoveFilter(item.name)}
                    ></i>
                  </div>
                ),
            )}
          </div>
          <div className="d-flex">
            <FilterTableSelection selection={selection} />
            <Tooltip
              content="Refresh"
              direction={TooltipDirection.BOTTOM}
              delay={0}
            >
              <button
                type="button"
                className="d-flex align-items-center btn btn-outline-primary refresh-button my-1"
                onClick={onRefreshHandler}
              >
                <i className="icon-refresh"></i>
              </button>
            </Tooltip>
            <Tooltip
              content="Filter"
              direction={TooltipDirection.BOTTOM}
              delay={0}
            >
              <button
                type="button"
                className="d-flex align-items-center btn btn btn-link filter-button me-1 ms-2"
                onClick={setShowHandler}
              >
                <i
                  className={`icon-sort custom-fs-2 me-2 ${
                    showFilter ? 'rotate' : ''
                  }`}
                ></i>
              </button>
            </Tooltip>
          </div>
        </div>
        <div
          className={`d-flex filter-form px-3 ${
            showFilter ? 'with-animation' : ''
          }`}
        >
          <DynamicForm
            inputs={items}
            initialValues={initialValues}
            onSubmit={onSubmit}
            ref={formRef}
          />
          <div className="d-flex align-items-end py-2">
            <button
              type="button"
              className={`d-inline-flex btn btn-outline-primary mx-1 ${styles.filterButton}`}
              onClick={resetHandler}
            >
              <i className="icon-reset custom-fs-2 me-2"></i>
              Reset all
            </button>
            <button
              type="button"
              className={`d-inline-flex btn btn-primary mx-1 ${styles.filterButton}`}
              onClick={applyFilters}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </div>
    );
  },
);
